<script setup lang="ts">
// TODO:
// - Determine based on theme config if the buttons should be rounded or not.
// - Maybe this component can be removed after upgrading Tairo, that has better button features.
// - Maybe rename it to "ThemeButton" or "ThemedButton"?
// - I was using bg-secondary-600 and bg-primary-600 for the hover state, but those didn't work
//   (for Oldebroek at least)
//   maybe we need to define default hover colors for the different variantsin the theme config?

const { variant = ContentButtonVariant.Primary, hoverPrefix = 'hover:' } = defineProps<{
  variant?: ContentButtonVariant
  // The prefix to use for group-hover, e.g. 'group-hover:' or 'group-hover/card:'
  hoverPrefix?: string
}>()

const variantClass = computed(() => {
  switch (variant) {
    case 'primary':
      return `bg-primary-500 text-white ${hoverPrefix}bg-secondary-500 font-semibold border-none`

    case 'secondary':
      return `bg-secondary-500 text-white ${hoverPrefix}bg-primary-500 font-semibold border-none`

    // Style used in the VacancyCategories block, on a colored background.
    case 'outline':
      return `bg-transparent border-white/80 text-white ${hoverPrefix}bg-white/10`

    case 'outline-secondary':
      return `bg-transparent border-secondary-500 text-secondary-500 ${hoverPrefix}bg-secondary-500/10`

    // Light background with primary text color.
    // Only use this on a colored background.
    case 'light':
      // Note that I made the text darker.
      return `bg-white/80 bg-transparent text-primary-700 ${hoverPrefix}bg-white/70`

    // White background with secondary text color.
    case 'white':
      return `bg-white text-secondary-500 ${hoverPrefix}bg-white/80 font-semibold border border-secondary-500 ${hoverPrefix}bg-secondary-500/10`
  }
})
</script>

<template>
  <NuxtLink
    class="text-md rounded-full border px-6 py-2"
    :class="variantClass"
  >
    <slot />
  </NuxtLink>
</template>
