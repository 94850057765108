<script setup lang="ts">
import { extractYouTubeVideoId, ContentFileImageVariant, tryImageVariant } from '#imports'

const { styling, card, headingSize = 'md', buttonVariant = ContentButtonVariant.Primary } = defineProps<{
  styling: DynamicCardComponentStyle
  card: DynamicCard
  headingSize?: 'md' | 'xl'
  buttonVariant?: ContentButtonVariant
}>()
const rootElement = ref(null)
defineExpose({ rootElement })

// Should the image be used as a background?
// If false, it's used as a header image
const imageAsBackground = styling == DynamicCardComponentStyle.PhotoBackground

// Minimum height for the card. Currently only used when imageAsBackground is true.
const minHeightWhenImageAsBackground = 'min-h-96'

// Show dark overlay on image?
const darkOverlay = !!(card.title || card.body)

const { cardRadiusClass } = useThemeClasses()

const backgroundClass = {
  [DynamicCardComponentStyle.DarkBackground]: 'bg-primary-500 dark:bg-primary-500 text-white',
  [DynamicCardComponentStyle.Default]: 'bg-white dark:bg-muted-900',
  [DynamicCardComponentStyle.PhotoBackground]: 'bg-white dark:bg-muted-900',
}

</script>

<template>
  <div
    class="overflow-hidden"
    :class="[cardRadiusClass, backgroundClass[styling]]"
  >
    <NuxtLink
      ref="rootElement"
      class="group/card flex h-full flex-col"
      :class="{
        'relative isolate': imageAsBackground,
        'cursor-pointer': !!card.buttonUrl
      }"
      role="button"
      tabindex="0"
      :to="card.buttonUrl ? card.buttonUrl : '#'"
    >
      
      <div
        v-if="card.image && imageAsBackground"
        class="absolute inset-0 -z-10 transition-all duration-300 group-hover:-inset-4"
      >
        <img
          class="size-full object-cover"
          :src="tryImageVariant(card.image, ContentFileImageVariant.Large) || undefined"
          :alt="card.title"
        >
        
        <div v-if="darkOverlay" class="absolute inset-0 bg-black/40" />
      </div>

      
      
      <div
        v-if="card.image && !imageAsBackground"
        class="relative mx-auto aspect-video w-full overflow-hidden pt-[56.25%]"
      >
        <img
          class="absolute left-0 top-0 aspect-video size-full object-cover transition-all duration-300 group-hover/card:scale-105"
          :src="tryImageVariant(card.image, ContentFileImageVariant.Large) || undefined"
          :alt="card.title"
        >
      </div>

      <div
        v-if="card.youtubeUrl"
        class="relative mx-auto w-full overflow-hidden"
      >
        <iframe
          :title="card.title"
          :class="['aspect-video w-full']"
          :src="`https://www.youtube-nocookie.com/embed/${extractYouTubeVideoId(card.youtubeUrl)}`"
          frameborder="0"
          allowfullscreen
        />
      </div>

      <div
        class="flex grow flex-col justify-between gap-y-3 px-5 pb-6 pt-4"
        :class="{
          'relative text-white': imageAsBackground,
          [minHeightWhenImageAsBackground]: imageAsBackground,
        }"
      >
        <BaseHeading
          :size="headingSize"
          as="h4"
          class="mt-1.5"
        >
          {{ card.title }}
        </BaseHeading>

        <TextParagraph
          v-if="card.body"
          size="sm"
          class="grow"
        >
          {{ card.body }}
        </TextParagraph>

        <BaseContentButton
          v-if="card.buttonText"
          :variant="buttonVariant"
          hover-prefix="group-hover/card:"
          class="mt-4 w-full"
          :to="card.buttonUrl"
        >
          {{ card.buttonText }}
        </BaseContentButton>
      </div>
    </NuxtLink>
  </div>
</template>
